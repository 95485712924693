import { takeLatest, put, select, call } from 'redux-saga/effects';

import toaster from '@giro/shared-store/toaster';
import auth from '@giro/shared-store/auth';

import dialogData from '.';

import postLinkService from '@giro/shared-services/link/postLink.service';
import postApiDebtsExternalIdService from '@giro/shared-services/debts/postApiDebtsExternalId.service';

import transation from '../../transactions';
import clients from '../../clients';

import { segmentNormalizeInvertConstant } from '@giro-pdv/constants/segmentNormalize.constant';

function* handleServicePost(action) {
  const user = yield select(auth.selector.selectUser);
  const dialog = yield select(dialogData.selector.selectState);

  const { payload } = action;

  const storeDialog = yield select(dialogData.selector.selectState);

  yield put(dialogData.action.fetchStart());

  const client = yield select(clients.selector.selectState);

  const link = `${process.env.REACT_APP_APP_URL}/debts/${client?.payload?.cdCliente}/${storeDialog.reference_id}`;

  const payloadFormatted = {
    destination: payload.destination,
    type: payload.type,
    link: link,
    link_type: 'Checkout',
  };

  try {
    const [success, data] = yield call(() => postLinkService(payloadFormatted));

    if (!success) {
      throw data;
    }

    if (success) {
      yield put(dialogData.action.fetchSuccess(data));

      yield put(
        transation.action.servicePost({
          isSend: true,
          sendObj: {
            link,
            whatsapp:
              payload.type === 'Whatsapp' ? payload.destination : undefined,
            email:
              payload.type !== 'Whatsapp' ? payload.destination : undefined,
          },
          ...dialog.payload,
        })
      );

      yield put(dialogData.action.close());
      yield put(
        toaster.action.show({
          message: 'Link de pagamento enviado com sucesso!',
          variant: 'success',
        })
      );
    }
  } catch (error) {
    yield put(dialogData.action.fetchError());
  }
}

function* handleSetOpen(action) {
  const { payload } = action;

  const user = yield select(auth.selector.selectUser);

  try {
    yield put(dialogData.action.fetchStart());

    const client = yield select(clients.selector.selectState);

    const [success, data] = yield call(() =>
      postApiDebtsExternalIdService(client?.payload?.cdCliente, {
        description: segmentNormalizeInvertConstant[payload?.segment],
        amount: payload?.total,
        installments: 0,
        properties: {
          fields: payload,
        },
      })
    );

    if (!success) {
      throw data;
    }

    const { reference_id } = data?.metadata || {};

    yield put(dialogData.action.updateReferenceId(reference_id));

    yield put(dialogData.action.fetchSuccess(data));
  } catch (error) {
    yield put(dialogData.action.fetchError());
  }
}

function* watch() {
  yield takeLatest(
    dialogData.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );
  yield takeLatest(dialogData.constant.ACTION_TYPES.SET.OPEN, handleSetOpen);
}

export default {
  watch,
};
